<template>
  <div style="display: flex; flex-direction: column">
    <h1 style="text-align: center">{{ $t('隐私政策') }}</h1>
    <div style="width: 960px; margin: 20px auto">
      1. The Main Points We will always keep your personal information safe. We
      will never sell your personal information. You can manage and review your
      marketing choices at any time. You can request details of the personal
      information that we hold on you at any time. 2. Introduction Currenxie
      Limited, (“Currenxie”, “CX”, “we” or “us”) together with its subsidiaries,
      developed this privacy policy to explain how we may collect, use, store,
      access and disclose your Personal Information (as defined below) when you
      use Currenxie’s Services. We treat your information privacy very seriously
      and are committed to protecting the Personal Information you provide to us
      in accordance with this Privacy Policy. The applicable law refers to
      European Union General Data Protection Regulation (Regulation (EU)
      2016/679) (GDPR), the United Kingdom General Data Protection Policy (GDPR
      UK), Australian Privacy Principles under the Data Privacy Act 1988, the
      Personal Data (Privacy) Ordinance (Cap. 486) of the Hong Kong Special
      Administrative Region (PDPO) and the Personal Information Protection Law
      of China (PIPL).
    </div>
  </div>
</template>

<script>
export default {
  name: 'privacyPolicy',
}
</script>

<style scoped></style>
